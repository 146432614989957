import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private router:Router){}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.body?.statusCode === 403) {
      this.router.navigateByUrl('/login');
    }
    const userInfor: any = JSON.parse(localStorage.getItem("stock-user"));
    if (userInfor) {
      const jwt = userInfor.accessToken;
      if (!!jwt) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      }
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
}
