<div class="bg-danger back container-fluid">
  <div class="container">
    <div class="row">
      <div class="justify-content-center text-center">
        <div class="text-white text-pro">your free trial has ended</div>
        <div class="text-white marg-top text-pron">Contact : <span class=" text-bold">0910507045</span></div>
        <div class="mt-2 text-white">Email : Mohammed@dabbalsoftwares.com</div>
        <div class="mt-2 text-white">Email : Feedback@dabbalsoftwares.com</div>
      </div>
    </div>
  </div>
</div>
