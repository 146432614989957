import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiUrl } from "../constants/app.constant";
import { Product } from "../models/product.model";
// import { AuthService } from './auth.service';
import { LoginService } from "./login.service";
import { ProductDto } from "../models/product";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private http: HttpClient, private auth: LoginService) {}

  async getProducts(): Promise<Observable<any>> {
    const branchId = await this.auth.getDefaultBranchId();
    return this.http.get<any>(`${ApiUrl.products}/${branchId}`);
  }

  addProduct(data: Product): Observable<any> {
    return this.http.post<any>(ApiUrl.product_new, data);
  }

  uploadSingle(data: ProductDto): Observable<any> {
    return this.http.post<any>(ApiUrl.product_new, data);
  }

  getProduct(id: number): Observable<any> {
    return this.http.get<any>(ApiUrl.product + "/" + id);
  }

  updateProduct(body, id) {
    return this.http.put<any>(`${ApiUrl.product}/${id}`, body);
  }

  deleteProduct(id, by) {
    return this.http.delete<any>(`${ApiUrl.product}/${id}/${by}`);
  }

  async searchProduct(data: any, num: number, field: string) {
    const branchId = await this.auth.getDefaultBranchId();
    var param = {
      query: data,
      branchId: branchId,
      limit: num,
      field: field,
    };
    return this.http.post<any>(ApiUrl.search, param);
  }

  async searchProductWithUUID(id: any) {
    const branchId = await this.auth.getDefaultBranchId();
    var param = {
      branchId: branchId,
      id: id,
    };
    return this.http.post<any>(ApiUrl.searchUUID, param);
  }

  async searchSoldProductWithUUID(id: any) {
    const branchId = await this.auth.getDefaultBranchId();
    var param = {
      branchId: branchId,
      id: id,
    };
    return this.http.post<any>(ApiUrl.searchSoldUUID, param);
  }

  async getColumnList(): Promise<Observable<any>> {
    const branch = await this.auth.getDefaultBranch();
    return this.http.get<any>(`${ApiUrl.columnName}/` + branch.type);
  }

  async searchSoldProduct(data: any, num: number, field: string) {
    const branchId = await this.auth.getDefaultBranchId();
    var body = {
      query: data,
      branchId: branchId,
      limit: num,
      field: field,
    };
    return this.http.post<any>(ApiUrl.searchSold, body);
  }

  saleProduct(body: any): Observable<any> {
    return this.http.post(ApiUrl.sale, body);
  }

  getSaleHistoryList(body: any): Observable<any> {
    return this.http.post(`${ApiUrl.saleHistory}`, body);
  }

  getMoveHistoryList(body: any): Observable<any> {
    return this.http.post(ApiUrl.moveHistory, body);
  }

  getReturnHistoryList(body: any): Observable<any> {
    return this.http.post(ApiUrl.returnHistory, body);
  }

  getRegisterHistoryList(body: any): Observable<any> {
    return this.http.post(ApiUrl.registerHistory, body);
  }

  getSaleDetailHistoryList(id: any): Observable<any> {
    return this.http.get(ApiUrl.saleHistory + "/" + id);
  }

  getSaleWCDetailHistoryList(id: any): Observable<any> {
    return this.http.get(ApiUrl.saleWcHistory + "/" + id);
  }

  getMoveDetailHistoryList(id: any): Observable<any> {
    return this.http.get(ApiUrl.moveHistory + "/" + id);
  }

  getReturnDetailHistoryList(id: any): Observable<any> {
    return this.http.get(ApiUrl.returnHistory + "/" + id);
  }

  getRegisterDetailHistoryList(id: any): Observable<any> {
    return this.http.get(ApiUrl.registerHistory + "/" + id);
  }

  getBranches(id: number): Observable<any> {
    return this.http.get(ApiUrl.branches);
  }

  async getAccountBranch(): Promise<Observable<any>> {
    const objId = await this.auth.getObjectId();
    return this.http.get(ApiUrl.branch);
  }
  getBranchTypes() {
    return this.http.get(ApiUrl.branchTypes);
  }

  getBranchType(id: number) {
    return this.http.get(`${ApiUrl.branchType}/${id}`);
  }

  addBranches(body: any): Observable<any> {
    return this.http.post(ApiUrl.branches, body);
  }

  addProductQuantity(body: any): Observable<any> {
    return this.http.post(ApiUrl.addProductQuantity, body);
  }

  moveProduct(body: any): Observable<any> {
    return this.http.post(ApiUrl.move, body);
  }

  returnProduct(body: any): Observable<any> {
    return this.http.post(ApiUrl.return, body);
  }

  addSaleWithCredit(body: any): Observable<any> {
    return this.http.post(ApiUrl.saleWithCredit, body);
  }

  getSaleWithCreditList(id: any): Observable<any> {
    return this.http.get(ApiUrl.saleWithCredit + "?id=" + id);
  }

  async getReportData(id: any) {
    const objId = await this.auth.getObjectId();
    return this.http.get(`${ApiUrl.dailyReport}?pId=${id}`);
  }

  getDefaultBranchId() {
    return this.auth.getDefaultBranchId();
  }

  async getBranchList(id: any) {
    return this.auth.getBranchList(id);
  }

  fetchDetailList(id: number, type: string) {
    return this.http.get(`${ApiUrl.dailyReportList}/${id}?type=${type}`);
  }

  fetchReportDetail(id: number, type: string): Observable<any> {
    return this.http.get(`${ApiUrl.dailyReportDetail}?type=${type}&id=${id}`);
  }

  getProductReport(id: number): Observable<any> {
    return this.http.get(`${ApiUrl.productReport}/${id}`);
  }

  async getOperationalHistory() {
    const objId = await this.auth.getObjectId();
    return this.http.get(`${ApiUrl.operationalHistory}/${objId}`);
  }

  async getSystemUsers(): Promise<Observable<any>> {
    const objId = await this.auth.getObjectId();
    return this.http.get(`${ApiUrl.systemUsers}/${objId}`);
  }

  getUserDetail(id: number) {
    return this.http.get(`${ApiUrl.user}/${id}`);
  }

  getUserBranches(id: number) {
    return this.http.get(`${ApiUrl.userBranches}/${id}`);
  }

  createUser(data: any) {
    return this.http.post(`${ApiUrl.user}`, data);
  }

  updateBranch(data: any, id: string) {
    return this.http.post(`${ApiUrl.userBranches}/${id}`, data);
  }

  updateUser(data: any, id: string) {
    return this.http.patch(`${ApiUrl.user}/${id}`, data);
  }

  getSaleWithCredit_List(branchId: string) {
    return this.http.get(`${ApiUrl.saleWithCredit_list}/${branchId}`);
  }

  getFinishedProduct() {
    return this.http.get(`${ApiUrl.finishProduct}`);
  }

  getCustomeReport(body: any, type: string) {
    return this.http.post(`${ApiUrl.customReport}?type=${type}`, body);
  }

  uploadAll(body: any) {
    return this.http.post(`${ApiUrl.uploadAll}`, body);
  }
}
