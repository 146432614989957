import {environment} from '../../environments/environment';

export class ApiUrl {
  private static baseUrl = environment.baseUrl;

  public static products = ApiUrl.baseUrl + "product/all";
  public static product_new = ApiUrl.baseUrl + "product/register";
  public static product = ApiUrl.baseUrl + "product";
  public static addProductQuantity = ApiUrl.baseUrl + "product/add/quantity";

  public static search = ApiUrl.baseUrl + "product/search";
  public static searchUUID = ApiUrl.baseUrl + "product/search/uuid";
  public static searchSold = ApiUrl.baseUrl + "product/search-sold";
  public static searchSoldUUID = ApiUrl.baseUrl + "product/search-sold/uuid";
  public static sale = ApiUrl.baseUrl + "product/sale";

  public static saleHistory = ApiUrl.baseUrl + "product/sale/history";
  public static saleWcHistory = ApiUrl.baseUrl + "product/salewc/history";
  public static moveHistory = ApiUrl.baseUrl + "product/move/history";
  public static returnHistory = ApiUrl.baseUrl + "product/return/history";
  public static registerHistory = ApiUrl.baseUrl + "product/register/history";
  public static uploadAll = ApiUrl.baseUrl + "/product/register/import";

  public static branches = ApiUrl.baseUrl + "branches";//getAccountBranch
  public static userBranches = ApiUrl.baseUrl + "user/branch";//getAccountBranch
  public static branch = ApiUrl.baseUrl + "branch/account";//getAccountBranch
  public static move = ApiUrl.baseUrl + "product/move";
  public static finishProduct = ApiUrl.baseUrl + "product/finished";

  public static return = ApiUrl.baseUrl + "product/return";
  public static saleWithCredit = ApiUrl.baseUrl + "product/sale-wc";
  public static saleWithCredit_list = ApiUrl.baseUrl + "product/salewc/list";
//   public static
  public static login = ApiUrl.baseUrl + "login";
  public static subscription = ApiUrl.baseUrl + "subscription";
  public static dailyReport = ApiUrl.baseUrl + "report/daily";
  public static dailyReportList = ApiUrl.baseUrl + "report/daily/list";
  public static dailyReportDetail = ApiUrl.baseUrl + "report/daily/detail";

  public static productReport  = ApiUrl.baseUrl + "report/product";
  public static columnName = ApiUrl.baseUrl + "branch/type"
  public static branchTypes = ApiUrl.baseUrl + "branch/types"
  public static branchType = ApiUrl.baseUrl + "branch/type"

  public static operationalHistory = ApiUrl.baseUrl + "report/history";
  public static systemUsers = ApiUrl.baseUrl + "user/system";
  public static user = ApiUrl.baseUrl + "user";
  static customReport = ApiUrl.baseUrl + "report/daily/custom";

  // otp 
  public static otp = ApiUrl.baseUrl + "send/otp";
  public static Verify = ApiUrl.baseUrl + "verify/otp";
  public static resetPassword = ApiUrl.baseUrl + "reset/password";
}
