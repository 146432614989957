import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-free-trial-ended',
  templateUrl: './free-trial-ended.component.html',
  styleUrls: ['./free-trial-ended.component.scss']
})
export class FreeTrialEndedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const el = document.getElementById("nb-global-spinner");
    if (el) {
      el.style["display"] = "none";
    }

  }

}
