import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { LoginService } from "../services/login.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from "@nebular/theme";
import Swal from "sweetalert2";

@Component({
  selector: "ngx-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  message: any;
  messagePlus: any;
  phone: string;
  password: string;
  confirmPassword: string;
  otp: number;
  showOtp: boolean = false;
  showPassword: boolean = false;

  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  preventDuplicates = false;
  config: NbToastrConfig;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  phoneValidator = {
    phone_number: {
      expression: (c: any) =>
        /(2519\d|09\d|2511\d|01\d|9\d)\d{7}$/.test(c.value),
      message: (error: any, field: FormlyFieldConfig) => `Invalid phone number`,
    },
  };

  isPhoneValid(phoneNumber: string): boolean {
    const value = /(2519\d|09\d|2511\d|01\d|9\d)\d{7}$/.test(phoneNumber);
    return value;
  }

  constructor(
    private auth: LoginService,
    private toastrService: NbToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.auth.loggedUserData;
    const el = document.getElementById("nb-global-spinner");
    if (el) {
      el.style["display"] = "none";
    }
    this.spinner.hide();
  }
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };

  submit() {
    ////console.log("data entered : ", this.model);
  }

  fields: FormlyFieldConfig[] = [
    {
      type: "input",
      key: "phone",
      templateOptions: {
        type: "phone",
        label: "Phone Number",
        required: true,
      },
      validators: this.phoneValidator,
    },
    {
      type: "input",
      key: "otp",
      templateOptions: {
        type: "otp",
        label: "otp",
        required: true,
      },
    },
  ];

  async sendOtp() {
    this.message = "";
    this.messagePlus = "";

    if (this.isPhoneValid(this.phone)) {
      if (this.phone.length == 10) {
        this.phone = this.phone.substring(1, this.phone.length);
        this.phone = "+251" + this.phone;
      }
      (await this.auth.sendOtpToPhone(this.phone)).subscribe((res) => {
        if (res.statusCode === 201) {
          this.messagePlus = res.data.message;
          this.showOtp = true;
        } else {
          this.message = res.message;
        }
      });
    } else {
      this.message = "Please enter valid phone number";
    }
  }

  async changePassword() {
    this.message = "";
    this.messagePlus = "";
    if (this.password.length < 6) {
      this.message = "Password length must be at least 6 characters";
      return;
    }

    if (this.password != this.confirmPassword) {
      this.message = "password and confirm password must be the same";
      return;
    }
    this.spinner.show();
    (
      await this.auth.resetPassword(this.phone, this.otp, this.password)
    ).subscribe((res) => {
      this.spinner.hide();
      Swal.fire("Changed Successfully",res.message,"success");
      this.router.navigate(["pages"]);
    });
  }

  async verifyOtp() {
    this.spinner.show();
    this.message = "";
    this.messagePlus = "";
    (await this.auth.verifyOtp(this.phone, this.otp)).subscribe((res) => {
      if (res.statusCode === 200) {
        this.showPassword = true;
        this.showOtp = false;
        this.messagePlus = res.message;
        this.auth.setLoggedUserData(res.user);
      } else {
        this.message = res.message;
      }
      this.spinner.hide();
    });
  }

  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `. ${title}` : "";

    this.index += 1;
    this.toastrService.show(body, ``, config);
  }
}
