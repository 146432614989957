import { Router } from "@angular/router";
import { Branch } from "./../../../models/branch";

import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { Subject } from "rxjs";
import { LoginService } from "../../../services/login.service";
import { DataService } from "../../../services/data.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  subscriptionStatus = "";
  user: any;
  id: any;
  branches: any[] = [];
  selectedBranch: any;
  shopName: any;
  daysLeft: number = 0;
  // themes = [
  //   {
  //     value: "default",
  //     name: "Light",
  //   },
  //   {
  //     value: "dark",
  //     name: "Dark",
  //   },
  //   {
  //     value: "cosmic",
  //     name: "Cosmic",
  //   },
  //   {
  //     value: "corporate",
  //     name: "Corporate",
  //   },
  // ];

  currentTheme = "Main";

  userMenu = [
    { title: "Profile", action: "clickedmenu()" },
    { title: "Log out", action: "clickedmenu1()" },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private service:DataService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private auth: LoginService,
    private spinner: NgxSpinnerService,
    
    private router: Router
  ) {}


  async userMenuEvent($event) {
    if ($event == "Profile") {
      this.router.navigateByUrl(
        `pages/admin/detail?id=${this.auth.loggedUserData.uid}`
      );
    }
    if ($event == "Log out") {
      localStorage.removeItem("stock-management-branch");
      localStorage.removeItem("stock-management-info");
      localStorage.removeItem("stock-user");
      localStorage.removeItem("br");
      this.router.navigateByUrl("/login");
    }
  }

  async ngOnInit() {
    this.spinner.show();
    this.id = this.auth.loggedUserData.objId;
    this.user = this.auth.loggedUserData;
    this.branches = [];
    if(this.user.role == "Owner"){
      this.branches = await this.auth.getBranchList(this.id).toPromise();
    }else{
      const res:any = await this.service.getUserBranches(this.user.id).toPromise();
      if(res.data){
        this.branches = res.data;
      }
    }
    var userData: string;
    const defaultB = await this.auth.getDefaultBranch();
    if(defaultB){
      this.selectedBranch = defaultB.name;
    }else{
      this.selectedBranch = "";
    }

    this.shopName = await this.auth.getShopName();
    this.spinner.hide();
    this.menuService.onItemClick().subscribe((event) => {
      this.userMenuEvent(event.item.title);
    });

    this.subscriptionStatus = await this.auth.getSubscriptionStatus();
    var date1 = new Date(this.auth.loggedUserData.createdAt);
    var date2 = new Date(this.auth.today.toDateString());
    if (this.subscriptionStatus == "Free Trial") {
      var Difference_In_Time = date2.getTime() - date1.getTime();

      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.daysLeft = 14 - Difference_In_Days;
      this.daysLeft = parseInt(this.daysLeft.toString());
      if (this.daysLeft <= 0) {
        localStorage.removeItem("stock-management-branch");
        localStorage.removeItem("stock-management-info");
        localStorage.removeItem("br");
        this.router.navigateByUrl("trial");
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeBranch($event) {
    const index = this.branches.filter(ele=>ele.name == $event);
    this.auth.changeDefaultBranch(index[0]);
    this.selectedBranch = $event;
    location.reload();
  }

  // changeTheme(themeName: string) {
  //   this.themeService.changeTheme(themeName);
  // }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
