import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { Component, HostListener, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";

// import { AuthService } from "../services/auth.service";
import { LoginService } from "../services/login.service";

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  message: any;
  phone: string;
  password: string;
  screenHeight: string = "700px";
  ngAfterViewInit() {
    this.screenHeight =window.innerHeight+'px !important';
  }

  phoneValidator = {
    phone_number: {
      expression: (c: any) =>
        /(2519\d|09\d|2511\d|01\d|9\d)\d{7}$/.test(c.value),
      message: (error: any, field: FormlyFieldConfig) => `Invalid phone number`,
    },
  };

  constructor(
    private auth: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    
    if (
      this.auth.loggedUserData == null ||
      this.auth.loggedUserData == undefined
    ) {
      ////console.log("should redirect to login");
    } else {
      ////console.log("goto dashboard");
    }

    this.auth.loggedUserData;

    const el = document.getElementById("nb-global-spinner");
    if (el) {
      el.style["display"] = "none";
    }
    this.spinner.hide();
  }
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };

  submit() {
    ////console.log("data entered : ", this.model);
  }

  fields: FormlyFieldConfig[] = [
    {
      type: "input",
      key: "phone",
      templateOptions: {
        type: "phone",
        label: "Phone Number",
        required: true,
      },
      validators: this.phoneValidator,
    },
    {
      type: "input",
      key: "password",
      templateOptions: {
        type: "password",
        label: "Password",
        required: true,
      },
    },
  ];

  isPhoneValid(phoneNumber: string): boolean {
    const value = /(2519\d|09\d|2511\d|01\d|9\d)\d{7}$/.test(phoneNumber);
    return value;
  }

  submitClicked() {
    this.model.phone = this.phone;
    this.model.password = this.password;
    if (this.form.valid) {
      if (this.isPhoneValid(this.phone)) {
        if (this.phone.length == 10) {
          this.phone = this.phone.substring(1, this.phone.length);
          this.phone = "+251" + this.phone;
        }
        this.spinner.show();
        this.auth.login(this.model).subscribe((data) => {
          if (data.user) {
            this.router.navigate(["pages"]);
            this.auth.setLoggedUserData(data.user);
          } else {
            this.message = data.message;
          }
          this.spinner.hide();
        });
      }  else {
        this.message = "Please enter valid phone number";
      }
    }
  }
}
