<nb-card>
  <nb-card-header></nb-card-header>
  <nb-card-body>
    <div class="login-dark" [style.height]="screenHeight">
      <form method="post">
        <h2 class="sr-only">Login Form</h2>
        <div class="illustration">
          <img src="../../assets/images/dabbal-stock.jpg" alt="dabbal stock " style="width:250px; margin-top:-20px;">
        </div>
        <p style="margin-top: 10px; margin-bottom: 10px; color:white;" class="text-center">hello! Log in with your phone
          and Password </p>
        <div *ngIf="message" class="text-danger text-center mt-3 mb-3">
          {{message}}
        </div>
        <div class="form-group"><input class="form-control" [(ngModel)]="phone" type="phone" name="phone"
            placeholder="Phone" required>
        </div>
        <div class="form-group"><input class="form-control" [(ngModel)]="password" type="password" name="password"
            placeholder="Password" required></div>
        <div class="form-group"><button class="btn btn-primary btn-block" type="submit" (click)="submitClicked()">Log
            In</button></div><a routerLink="/reset" class="forgot">Forgot your password or change password?</a>
      </form>
    </div>

  </nb-card-body>
</nb-card>