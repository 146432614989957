<nb-card>
    <nb-card-header></nb-card-header>
    <nb-card-body>
        <div class="login-dark">
            <form method="post">
                <h2 class="sr-only">Chnage Password Form</h2>
                <div class="illustration">
                    <img src="../../assets/images/dabbal-stock.jpg" alt="dabbal stock "
                        style="width:250px; margin-top:-20px;">
                </div>
                <p style="margin-top: 10px; margin-bottom: 10px; color:white;" class="text-center">hello! change
                    password with your phone</p>
                <div *ngIf="message" class="text-danger text-center mt-3 mb-3">
                    {{message}}
                </div>
                <div *ngIf="messagePlus" class="text-success text-center mt-3 mb-3">
                    {{messagePlus}}
                </div>
                <div class="form-group"><input class="form-control" [(ngModel)]="phone" type="phone" name="phone"
                        placeholder="Phone" [disabled]="showOtp" pattern="(2519\d|09\d|2511\d|01\d|9\d)\d{7}$" required>
                </div>
                <div class="form-group" *ngIf="showOtp"><input class="form-control" [(ngModel)]="otp" type="number"
                        name="otp" placeholder="Enter otp" required></div>
                <div class="form-group" *ngIf="showPassword"><input class="form-control" [(ngModel)]="password"
                        type="text" name="password" placeholder="Enter New Password" required></div>
                <div class="form-group" *ngIf="showPassword"><input class="form-control" [(ngModel)]="confirmPassword"
                        type="text" name="confirmPassword" placeholder="Confirm Password" required></div>
                <div class="form-group" *ngIf="showOtp"><button class="btn btn-primary btn-block" type="submit"
                        (click)="verifyOtp()">Verify Otp</button>
                </div>
                <div class="form-group" *ngIf="showPassword"><button class="btn btn-primary btn-block" type="submit"
                        (click)="changePassword()">Change Password</button>
                </div>
                <div class="form-group" *ngIf="!showOtp  && !showPassword"><button class="btn btn-primary btn-block"
                        type="submit" (click)="sendOtp()">Send
                        OTP</button>
                </div>
                <a class="forgot mt-1 mb-1" *ngIf="showOtp && !showPassword" style="cursor: pointer;" (click)="sendOtp()">send otp again</a>
                <!-- <a class="forgot mt-1 mb-1" *ngIf="!showOtp && !showPassword">I have otp</a> -->
                <a routerLink="/login" class="forgot">Login with your password?</a>
            </form>
        </div>

    </nb-card-body>
</nb-card>