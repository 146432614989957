import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiUrl } from "../constants/app.constant";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  loggedUserData: any;
  defaultBranch: any;
  branchList: any;
  today: Date;
  constructor(private http: HttpClient) {
    this.today = new Date();
  }

  login(data: any): Observable<any> {
    return this.http.post<any>(ApiUrl.login, data);
  }

  getLoggedUserData(): any {
    if (!this.loggedUserData) {
      const data = JSON.parse(localStorage.getItem("stock-user"));
      this.loggedUserData = data;
    }
    return this.loggedUserData;
  }

  setLoggedUserData(data: any): any {
    this.loggedUserData = data;
    localStorage.setItem("stock-user", JSON.stringify(data));
  }

  setLoggedUserDataFromLocal(): any {
    this.loggedUserData = JSON.parse(localStorage.getItem("stock-user"));
  }

  updateLoggedUserData(data: any) {
    this.loggedUserData = data;
  }

  async getUserRole() {
    if (this.loggedUserData) {
      this.setLoggedUserDataFromLocal();
    }
    return this.loggedUserData.role;
  }

  changeInitializationStatus(int: any) {
    this.isInitializedSource.next(int);
  }

  private isInitializedSource = new BehaviorSubject<any>(false);
  isInitialized = this.isInitializedSource.asObservable();

  getBranchList(id: string): Observable<any> {
    return this.http.get<any>(ApiUrl.branches);
  }

  async loadBranch() {
    if (!this.branchList) {
      await this.setBranchList();
    }
    this.defaultBranch = this.branchList[0];
    //console.log("this.barnchList ; ",this.branchList);
    if (this.defaultBranch != undefined)
      localStorage.setItem("br", JSON.stringify(this.defaultBranch));
  }

  async getDefaultBranchFromLocal() {
    const data = localStorage.getItem("br");
    //console.log("data ; ",data)
    if (data == undefined || data == null || data == "undefined") {
      await this.loadBranch();
    } else {
      this.defaultBranch = JSON.parse(data);
    }
  }
  async getDefaultBranch() {
    if (this.defaultBranch == undefined) {
      await this.getDefaultBranchFromLocal();
    }
    // //console.log("defaultBranch : ",this.defaultBranch);
    return this.defaultBranch;
  }

  changeDefaultBranch(data: any) {
    this.defaultBranch = data;
    localStorage.setItem("br", JSON.stringify(data));
  }

  async setBranchList() {
    const data = this.getLoggedUserData();
    if (this.loggedUserData.role == "Owner") {
      const list = await this.getBranchList(data.objId).toPromise();
      this.branchList = list;
    } else {
      const res: any = await this.getUserBranches(
        this.loggedUserData.id
      ).toPromise();
      if (res.data) {
        this.branchList = res.data;
      }
    }
  }

  getUserBranches(id: number) {
    return this.http.get(`${ApiUrl.userBranches}/${id}`);
  }

  async getDefaultBranchId() {
    await this.getDefaultBranch();
    return this.defaultBranch.id;
  }

  async getBranchListLocal() {
    if (!this.branchList) {
      await this.setBranchList();
    }
    return this.branchList;
  }

  getShopName() {
    const data = this.getLoggedUserData();
    return data.name;
  }

  getSubsriptionStatusInfo(id: number): Observable<any> {
    return this.http.get<any>(`${ApiUrl.subscription}/${id}`);
  }

  async loadSubscriptionStatusInfo() {
    const data = this.getLoggedUserData();
    this.subInfo = await this.getSubsriptionStatusInfo(
      data.account.subscriptionId
    ).toPromise();
  }

  async getSubscriptionStatus() {
    if (!this.subInfo) {
      await this.loadSubscriptionStatusInfo();
    }
    //console.log("subInfo : ",this.subInfo);
    return this.subInfo.data.title;
  }
  subInfo: any;

  async getObjectId() {
    if (!this.loggedUserData) {
      await this.getLoggedUserData();
    }
    return this.loggedUserData.objId;
  }

  async sendOtpToPhone(phoneNumber: string): Promise<Observable<any>> {
    return this.http.post<any>(`${ApiUrl.otp}`, { phone: phoneNumber });
  }

  async verifyOtp(phoneNumber: string, otp: number): Promise<Observable<any>> {
    return this.http.post<any>(`${ApiUrl.Verify}`, {
      phone: phoneNumber,
      otp: otp,
    });
  }

  async resetPassword(
    phoneNumber: string,
    otp: number,
    password: string
  ): Promise<Observable<any>> {
    return this.http.post<any>(`${ApiUrl.resetPassword}`, {
      phone: phoneNumber,
      otp: otp,
      password: password,
    });
  }
}
